import React from "react"
import { ShowcaseCard, Carousel, Tagline } from "../../../../components"

const CaseStudies = ({ data, t }) => {
  const handleClick = url => {
    // Save the current scroll position to localStorage
    localStorage.setItem("scrollPosition", window.scrollY.toString())
    // Navigate to the target URL
    window.location.href = url
  }

  return (
    <React.Fragment>
      <div className="carousel__tagline">
        <h2>{t("tagline.case-studies")}</h2>
      </div>
      <Carousel arrows={true} data={data.list}>
        {data.list.map((work, i) => (
          <div key={i}>
            <a
              href={`/case-studies/${work.slug}/`}
              onClick={e => {
                e.preventDefault()
                handleClick(`/case-studies/${work.slug}/`)
              }}
            >
              <ShowcaseCard
                imageUrl={work.image.url}
                imageAlt={work.image.alt}
                title={work.title}
                descr={work.content}
              />
            </a>
          </div>
        ))}
      </Carousel>
    </React.Fragment>
  )
}

export default CaseStudies
