import _ from 'lodash'
import React from 'react'
import DownloadIcon from '../../../../assets/icons/seasongroup_icon_download.svg'

const DownloadsServices = ({
  data,
  t,
}) => (
  <div className='row downloads__container'>
    <div className='col-12 col-md-4'>
      <h2>{t('service.downloads.title')}</h2>
    </div>
    <div className='col-12 col-md-8'>

      <div className='row'>
        {
          _.map(data.list, (item, index) => (
            <div className='col-12 col-lg-6 col-xl-4 col-xxl-3 d-flex downloads__card__container' key={index}>
              <div className='downloads__card__box'>
                <h4>{_.get(item, 'title')}</h4>
                <p className="downloads__card__button">
                  <span>{t('service.downloads.titleButton')}</span>
                  <DownloadIcon width={20} />
                </p>
              </div>
            </div>
          ))
        }
      </div>
    </div>

  </div>
)

export default DownloadsServices
